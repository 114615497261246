<template>
  <div class="vx-row">
    <vx-card>
      <h5 style="margin-bottom:9px">Cierre de caja</h5>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  closes.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : closes.length
                }}
                de {{ closes.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
          <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
          <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
          <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
          <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="closes"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @row-clicked="onRowSelected"
        :modules="modules"
      >
      </ag-grid-vue>
      <ag-grid-vue style="height: 60px; flex: none;"
        class="ag-theme-material w-100 ag-grid-table total"
        :gridOptions="bottomGridOptions"
        :headerHeight="0"
        :floatingFiltersHeight="0"
        :columnDefs="columnDefs"
        :rowData="closesFooter"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </vx-card>
    <sale-details :data="sale_details" :isPopupActive="popupActive" @closePopup="togglePopup" />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import { AgGridVue } from "@ag-grid-community/vue";
import reportModule from "@/store/report/moduleReport.js";
import DatePicker from "vuejs-datepicker";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import * as lang from 'vuejs-datepicker/src/locale';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import SaleDetails from './popup-table.vue';

export default {
  data() {
    return {
      from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      languages: lang,

      //Table
      searchQuery: "",
      gridOptions: {
        alignedGrids: [],
        suppressHorizontalScroll: true
      },
      modules: AllCommunityModules,

      maxPageNumbers: 12,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      groupHeaderHeight: 150,
      headerHeight: 100,
      columnDefs: [
        {
          headerName: "Id Turno",
          field: "id_till_init",
          width: 115,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Fecha Inicio",
          field: "fecha_inicio",
          width: 125,
          filter: true,
          floatingFilter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Usuario Inicio",
          field: "name_open",
          width: 200,
          filter: true,
        },
        {
          headerName: "Inicio Caja",
          field: "inicio_caja",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.inicio_caja, '$ '),
        },
        {
          headerName: "Ventas Tiptap",
          field: "venta_tiptap",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.venta_tiptap, '$ '),
        },
        {
          headerName: "Ventas Tarjeta",
          field: "venta_tarjeta",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.venta_tarjeta, '$ '),
        },
        {
          headerName: "Ventas Efectivo",
          field: "venta_efectivo",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.venta_efectivo, '$ '),
        },
        {
          headerName: "Recargas Efectivo",
          field: "venta_recargas",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.venta_recargas, '$ '),
        },
        {
          headerName: "Cortesía",
          field: "venta_courtesia",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.venta_courtesia, '$ '),
        },
        {
          headerName: "Total General",
          field: "total_general",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total_general, '$ '),
        },
        {
          headerName: "Total Efectivo",
          field: "total_efectivo",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total_efectivo, '$ '),
        },
        {
          headerName: "Diferencia",
          field: "diferencia",
          width: 125,
          filter: true,
          floatingFilter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.diferencia, '$ '),
          cellStyle: params => params.data.diferencia == 0 ? {color: 'inherit'} : {color: 'red', 'font-weight': 700},
        },
        {
          headerName: "Fecha Cierre",
          field: "fecha_cierre",
          width: 125,
          filter: true,
          floatingFilter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Usuario Cierre",
          field: "name_close",
          width: 200,
          filter: true,
          floatingFilter: true
        },
      ],
      closes: [],
      closesFooter: [],
      bottomGridOptions: {
        alignedGrids: [],
        defaultColDef: {
          editable: false,
          sortable: true,
          resizable: true,
          filter: true,
          flex: 1,
          minWidth: 100,
          floatingFiltersHeight: 0
        }
      },
      sale_details: {
        sales: [],
        products: []
      },
      popupActive: false,
    };
  },
  components: {
    VuePerfectScrollbar,
    VueApexCharts,
    AgGridVue,
    DatePicker,
    SaleDetails
  },

  created() {
    //Modules
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }
    this.loadCloseTable();
  },

  methods: {
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf())
    },
    loadCloseTable() {
      this.closesFooter = [];
      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

      this.$store.dispatch("reportModule/getClose",{id:this.$store.state.AppActiveUser.institution.id, dates:dates})
        .then((response) => {
          if (response.data.closes.length > 0) {
            let s = JSON.parse(JSON.stringify(response.data.closes));
            let s_footer = {};
            s_footer.id_till_init = "Total";
            s_footer.venta_tarjeta = 0;
            s_footer.venta_tiptap = 0;
            s_footer.venta_efectivo = 0;
            s_footer.venta_recargas = 0;
            s_footer.venta_courtesia = 0;
            s_footer.total_general = 0;

            var options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "UTC"
            };

            let accounts = response.data.accounts;

            s.forEach((element) => {
              element.fecha_cierre = new Date(element.fecha_cierre).toLocaleDateString("es-AR", options);
              element.fecha_inicio = new Date(element.fecha_inicio).toLocaleDateString("es-AR", options);
              let user_open =  accounts.find(a => a.id_account == element.id_account_open);
              let user_close =  accounts.find(a => a.id_account == element.id_account_close);

              element.name_open = user_open ? user_open.name + " " + user_open.last_name : "";
              element.name_close = user_close ? user_close.name + " " + user_close.last_name : "";

              // response.data.accounts.forEach((staff) => {
              //   element.name = staff.name + " " + staff.last_name;
              // })

              s_footer.venta_tarjeta += element.venta_tarjeta !== null ? element.venta_tarjeta : 0;
              s_footer.venta_tiptap += element.venta_tiptap !== null ? element.venta_tiptap : 0;
              s_footer.venta_efectivo += element.venta_efectivo !== null ? element.venta_efectivo : 0;
              s_footer.venta_recargas += element.venta_recargas !== null ? element.venta_recargas : 0;
              s_footer.venta_courtesia += element.venta_courtesia !== null ? element.venta_courtesia : 0;
              s_footer.total_general += element.total_general !== null ? element.total_general : 0;
            });

            this.closesFooter = [s_footer]
            this.closes = s;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
    onRowSelected(event) {
      if(!event.node.selected)
        return;

      if(event.data && event.data.id_till_init && event.data.id_till_init > 0) {
        this.sale_details.sales = []
        this.$store.dispatch(`reportModule/getTillDetail`, event.data.id_till_init)
          .then(response => {
            this.sale_details.products = response.data;
            this.sale_details.sales.push(event.data);
            this.togglePopup(true);
          })
          .catch(error => console.log(error))
      }
    },
    togglePopup(val=false) {
      this.popupActive = val
    },
  },

  watch: {
      from: function () {
        this.loadCloseTable();
      },
      to: function () {
        this.loadCloseTable();
      },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 12;
      }
    },
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
};
</script>

<style>
  .ag-header-cell-label .ag-header-cell-text{
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
  .ag-grid-table.total .ag-row {
    font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
    border: none;
  }
</style>
